import { ChatType } from '../components/chat-list-section/chat-row/typings';

const colors: { [key: string]: string } = {
  '0': '#c4616b',
  '1': '#c06382',
  '2': '#ae5fc0',
  '3': '#8959e5',
  '4': '#596bd7',
  '5': '#4d90cc',
  '6': '#317694',
  '7': '#3e9892',
  '8': '#57af5c',
  '9': '#7fa453',
};

export const getColorFromPhone = ({
  phone,
  name,
  chatType,
}: {
  phone: string;
  name: string;
  chatType?: ChatType;
}) => {
  try {
    if (chatType === ChatType.GROUP) return '#29B6F6';

    const lastDigit = phone[phone.length - 1];

    return colors[lastDigit];
  } catch (error) {
    return '#F0F4C3';
  }
};
