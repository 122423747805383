import mixpanel from 'mixpanel-browser';

import getUserDetails from '../../modules/home/v1/requests/get-user-details';
import appConfig from '../../shared/config/app';
import { Organization } from '../../modules/login/v1/typings';

let registered = false;

export const isAnalyticsRegistered = () => {
  return registered;
};

const initAnalytics = async () => {
  mixpanel.init(appConfig.mixPanelToken, {
    debug: process.env.REACT_APP_ENV === 'development',
    ignore_dnt: true
  });
};

const registerAnalytics = async (
  orgId: string,
  organizations: Organization[]
) => {
  if (!orgId) return;

  try {
    // const [profile] = await Promise.all([]);
    const profile = await getUserDetails();

    const org = organizations?.find(({ orgId: id }) => id === orgId);

    if (!org) return;

    const { phone, id, name } = profile;

    if (!registered) {
      const uniqueId = `${orgId}_${id}`;

      mixpanel.identify(uniqueId);
      mixpanel.register({
        active_org_id: orgId,
        active_org_name: org.name,
        name: name,
        org_list: organizations,
      });

      mixpanel.register_once({ user_id: id, login_phone_number: phone });

      registered = true;
    }

    return { profile, organizations };
  } catch (error) {
    registered = false;
  }
};

export { initAnalytics, registerAnalytics };
