import { SetStateAction, Dispatch } from 'react';

export interface Param {
  id: string;
}

export enum CHAT_UPDATE_TYPE {
  ADD,
  UPDATE,
  DELETE,
  ACCESS_UPDATES,
  API_UPDATES,
  PARTIAL_UPDATE,
}

export enum Status {
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  READ = 'READ',
  FAILED = 'FAILED',
}

export enum SENDER_TYPE {
  USER = 'USER',
  SYSTEM = 'SYSTEM',
  CUSTOMER = 'CUSTOMER',
}

export interface MessageStatus {
  chatId: string;
  messageId: string;
  status: Status;
  senderType: SENDER_TYPE;
  statusTimestamp: number;
  errorMessage?: string;
  senderId: string;
  orgId: string;
  integrationId: string;
}

export interface GroupMessageStatus {
  chatId: string;
  chatMessageId: string;
  orgId: string;
  deliveryCount: number;
  erroredCount: number;
  readCount: number;
  repliesCount: number;
  sentCount: number;
  totalCount?: number;
  quickActionStatsButton: Array<{ text: string; count: number }>;
  integrationId: string;
  unreadCount: number;
  noResponseCount: number;
}

export interface LinkAnalyticsStatus {
  chatId: string;
  chatMessageId: string;
  orgId: string;
  linkId: string;
  linkVisitCount: number;
}

export enum BroadcastPageDetails {
  DELIVERED = 'delivered',
  SENT = 'sent',
  READ = 'read',
  REPLIED = 'replied',
  FAILED = 'failed',
  BUTTON_CLICK = 'buttonClicks',
  UNREAD = 'unread',
  NO_RESPONSE = 'noResponse',
  LINK_ANALYTICS = 'linkAnalytics',
}

export const BroadcastStatMessage = {
  SENT: 'Sent to',
  DELIVERED: 'Delivered to',
  READ: 'Read by',
  REPLIED: 'Replied by',
  FAILED: 'Failed',
  UNREAD: 'Unread by',
  NO_RESPONSE: 'No response by',
};

export interface ChatUnreadCountBroadcast {
  chatId: string;
  orgId: string;
  unreadCount: number;
  validUserIds?: string[];
}

export enum PopupName {
  DELETE_POPUP = 'DELETE_POPUP',
  UNASSIGN_POPUP = 'UNASSIGN_POPUP',
  APPLY_TAG_POPUP = 'APPLY_TAG_POPUP',
  BROADCAST_LIST_POPUP = 'BROADCAST_LIST_POPUP',
  ASSIGN_ELSE_POPUP = 'ASSIGN_ELSE_POPUP',
}
export interface PopupConfirmationModalProps {
  open: boolean;
  type: string;
  onClick: () => Promise<any>;
  onClose: () => void;
}

export interface AssignModalProps {
  open: boolean;
  onClose: () => void;
  selectedUserId: string;
  setSelectedUserId: Dispatch<SetStateAction<string>>;
  handleOnClickAssignUser: () => Promise<void>;
}

export enum AddTagAssignType {
  SINGLE_CHAT = 'SINGLE_CHAT',
  MULTIPLE_CHAT = 'MULTIPLE_CHAT',
}

export interface ConfirmationModalStateProps {
  open: boolean;
  message: string;
  onClick: () => Promise<any>;
  onClose: () => void;
  okButtonText: string;
  buttonBgColor: string;
}

export enum BlockUnblockCustomer {
  BLOCK = 'BLOCK',
  UNBLOCK = 'UNBLOCK',
  NONE = 'NONE',
}

export interface ChannelMembersInfo {
  memberCount: number;
  showMemberCount: boolean;
}
