import { atom } from 'jotai';
import { Message } from '../typings/message-types';

interface BroadcastMessageInfo {
  messageId: string;
  chatId: string;
  pageDetail: string;
  buttonText: string;
  chatTypeId: string;
  linkId: string;
}

type MessageInfo = {
  message: Message;
  customerId: string;
  chatId: string;
};

interface BroadcastMembersInfo {
  channelId: string;
}

export enum DetailsPanelState {
  CUSTOMER_DETAILS = 'customer-details',
  BROADCAST_MESSAGE = 'broadcast-message',
  MESSAGE_INFO = 'message-info',
  BROADCAST_MEMBERS = 'broadcast-members',
}

type DetailsPanelAtom =
  | {
      open: false;
    }
  | (
      | {
          open: true;
          type: DetailsPanelState.CUSTOMER_DETAILS;
        }
      | { open: true; type: DetailsPanelState.MESSAGE_INFO; data: MessageInfo }
      | {
          open: true;
          type: DetailsPanelState.BROADCAST_MESSAGE;
          data: BroadcastMessageInfo;
        }
      | {
          open: true;
          type: DetailsPanelState.BROADCAST_MEMBERS;
          data: BroadcastMembersInfo;
        }
    );

export const detailsPanelAtom = atom<DetailsPanelAtom>({
  open: false,
});
