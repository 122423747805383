import {
  ChatFilterSlaStatus,
  ChatListReducerAction,
  ChatListReducerActionTypes,
  DEFAULT_CHAT_FILTERS,
} from './../typings/index';
import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import { createRequestAtom } from '../../../../shared/utils/request-atom';
import { ChatListAtom } from '../typings';
import { ListRange } from 'react-virtuoso';
import { GetChatStatusFilterCount } from '../components/chat-list-section/chat-row/typings';
import { getDefaultChatFilters } from '../utils/get-default-filters';

export const chatListAtom = atomWithReset<ChatListAtom>(
  getDefaultChatFilters()
);

export const chatFilterStatusCountsAtom =
  createRequestAtom<GetChatStatusFilterCount[]>();

export const chatListRangeAtom = atom<ListRange | undefined>(undefined);

export const chatFiltersSetter = atom(
  (get) => get(chatListAtom),
  (get, set, action: ChatListReducerAction) => {
    const { type, data } = action;
    if (type === ChatListReducerActionTypes.CUSTOM_LIST) {
      const filters = get(chatListAtom);
      const listFilters = filters.customList;

      set(chatListAtom, {
        ...filters,
        customList: {
          ...listFilters,
          [data.listId]: data.fieldId,
        },
      });
      return;
    } else if (type === ChatListReducerActionTypes.RESET) {
      set(chatListAtom, DEFAULT_CHAT_FILTERS);
      return;
    } else if (
      type === ChatListReducerActionTypes.TAGS &&
      !Array.isArray(data)
    ) {
      const filters = get(chatListAtom);
      const tags = filters.tags;

      const tagsSet = new Set(tags);

      if (tagsSet.has(data)) {
        tagsSet.delete(data);
      } else {
        tagsSet.add(data);
      }

      set(chatListAtom, {
        ...filters,
        tags: Array.from(tagsSet),
      });

      return;
    } else if (
      type === ChatListReducerActionTypes.WABA &&
      !Array.isArray(data)
    ) {
      const filters = get(chatListAtom);
      const wabas = filters.waba;

      const wabasSet = new Set(wabas);

      if (wabasSet.has(data)) {
        wabasSet.delete(data);
      } else {
        wabasSet.add(data);
      }

      set(chatListAtom, {
        ...filters,
        waba: Array.from(wabasSet),
      });

      return;
    } else if (
      type === ChatListReducerActionTypes.SLA_STATUS &&
      [
        'breached' as ChatFilterSlaStatus,
        'non-breached' as ChatFilterSlaStatus,
      ].indexOf(data) > -1
    ) {
      set(chatListAtom, {
        ...get(chatListAtom),
        slaStatus: data,
      });
    } else if (type === ChatListReducerActionTypes.MIXED) {
      set(chatListAtom, {
        ...get(chatListAtom),
        ...data,
      });
      return;
    }
    if (type) {
      set(chatListAtom, {
        ...get(chatListAtom),
        [type]: data,
      });
    }
  }
);

export enum FiltersState {
  ALL_CHATS = 'ALL_CHATS',
  MY_CHATS = 'MY_CHATS',
  UNASSIGNED = 'UNASSIGNED',
  SLA_STATUS = 'SLA_STATUS',
  STATUS = 'STATUS',
  TAGS = 'TAGS',
  ASSIGNEE = 'ASSIGNEE',
  WABA = 'WABA',
  NONE = 'NONE',
}

export const showFiltersAtom = atom<{
  open: boolean;
  state?: FiltersState;
}>({
  open: true,
  state: FiltersState.NONE,
});

export const toggleSelectedChatIdsAtom = atom<boolean>(false);

export const selectedChatIdsListAtom = atom<string[]>([]);
