export enum ShopifyEventType {
  CART_ABANDONMENT = 'CART_ABANDONMENT',
  ORDER_COMPLETION = 'ORDER_COMPLETION',
  ORDER_FULFILLED = 'ORDER_FULFILLED',
  FOLLOW_UP_POST_PURCHASE = 'FOLLOW_UP_POST_PURCHASE',
}

export enum ShopifyEventDurationUnit {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
}

export interface ShopifyEventsStatus {
  [ShopifyEventType.CART_ABANDONMENT]: boolean;
  [ShopifyEventType.ORDER_COMPLETION]: boolean;
  [ShopifyEventType.ORDER_FULFILLED]: boolean;
  [ShopifyEventType.FOLLOW_UP_POST_PURCHASE]: boolean;
}

export const EVENT_DURATION_UNIT_MAPPING = {
  [ShopifyEventType.CART_ABANDONMENT]: ShopifyEventDurationUnit.MINUTES,
  [ShopifyEventType.ORDER_COMPLETION]: ShopifyEventDurationUnit.MINUTES,
  [ShopifyEventType.ORDER_FULFILLED]: ShopifyEventDurationUnit.MINUTES,
  [ShopifyEventType.FOLLOW_UP_POST_PURCHASE]: ShopifyEventDurationUnit.MINUTES,
};

export interface CustomVariablesData {
  customVariables: Record<string, string>;
}

export type Variable = {
  type: string;
} & ({ text: string } | { link: string });

export interface TemplateVariables {
  variables: Variable[];
}

export interface TemplateData {
  templateId: number;
  templateVariables: TemplateVariables;
}

export interface ShopifyEvent {
  type: ShopifyEventType;
  enabled: boolean;
  template: TemplateData;
  durationUnit: ShopifyEventDurationUnit;
  durationInterval: number;
}

export enum ShopifyShopStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
}

export interface ShopifyStateData {
  enabled: boolean;
  status: ShopifyShopStatus;
  shopLink: string | null;
  events: ShopifyEvent[] | null;
}

export interface TemplateMessage {
  id: number;
  variables: Variable[];
}

export interface Message {
  messageType: string;
  templateMessage: TemplateMessage;
}

export interface ActivateActionData {
  eventType: ShopifyEventType;
  durationUnit: ShopifyEventDurationUnit;
  durationInterval: number;
  message: Message;
}
