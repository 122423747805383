import { FC, SVGProps } from 'react';

const ArrowRightFilled: FC<
  SVGProps<SVGSVGElement> & { size?: number; color?: string }
> = ({ color = '#2d2d2d', size = 24, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      color={color}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="20" height="20" rx="4" fill="#007A5A" />
      <path
        d="M13.4792 10.8333H4.16671C3.9306 10.8333 3.73268 10.7535 3.57296 10.5937C3.41324 10.434 3.33337 10.2361 3.33337 10C3.33337 9.76389 3.41324 9.56597 3.57296 9.40625C3.73268 9.24653 3.9306 9.16667 4.16671 9.16667H13.4792L9.39587 5.08333C9.22921 4.91667 9.14935 4.72222 9.15629 4.5C9.16323 4.27778 9.25004 4.08333 9.41671 3.91667C9.58337 3.76389 9.77782 3.68403 10 3.67708C10.2223 3.67014 10.4167 3.75 10.5834 3.91667L16.0834 9.41667C16.1667 9.5 16.2257 9.59028 16.2605 9.6875C16.2952 9.78472 16.3125 9.88889 16.3125 10C16.3125 10.1111 16.2952 10.2153 16.2605 10.3125C16.2257 10.4097 16.1667 10.5 16.0834 10.5833L10.5834 16.0833C10.4306 16.2361 10.2396 16.3125 10.0105 16.3125C9.78129 16.3125 9.58337 16.2361 9.41671 16.0833C9.25004 15.9167 9.16671 15.7188 9.16671 15.4896C9.16671 15.2604 9.25004 15.0625 9.41671 14.8958L13.4792 10.8333Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowRightFilled;
