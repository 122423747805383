interface LogoProps {
  height?: number;
  width?: number;
}
export default function Logo({ width = 42, height = 42 }: LogoProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 512 512"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>DoubleTick</title>
      <defs></defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group">
          <rect id="Rectangle" x="0" y="0" width="512" height="512"></rect>
          <g
            id="dt-icon-(1)"
            transform="translate(74.000000, 155.000000)"
            fill="#28B379"
            fillRule="nonzero"
          >
            <path
              d="M248.056063,0 L157.548298,90.3195866 L179.471229,112.19694 L269.978978,21.8773438 L248.056063,0 Z M341.077084,0 L181.500002,159.245325 L114.943944,92.8276345 L93.0210229,114.704972 L181.500002,203 L363,21.8773438 L341.077084,0 Z M21.9229224,92.8276345 L0,114.704972 L81.4236747,195.989595 L103.376878,174.082027 L21.9229224,92.8276345 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
