import { chatListFamily } from './chat';
import { atom } from 'jotai';
import { ChatRowData } from '../components/chat-list-section/chat-row/typings';

export const selectedChatIdAtom = atom<string | undefined>(undefined);
export const externalSelectedChat = atom<ChatRowData | undefined>(undefined);

export const selectedChatIdSetter = atom(
  (get) => get(selectedChatIdAtom),
  (get, set, id: string) => {
    const prevSelectedChatId = get(selectedChatIdAtom);
    set(externalSelectedChat, undefined);
    set(selectedChatIdAtom, id);

    if (prevSelectedChatId && prevSelectedChatId !== id) {
      const olderChat = get(chatListFamily({ id: prevSelectedChatId }));

      if (olderChat) {
        set(chatListFamily({ id: prevSelectedChatId }), {
          ...olderChat,
          selected: false,
        });
      }
    }

    const chat = get(chatListFamily({ id }));
    if (chat) {
      set(chatListFamily({ id }), {
        ...chat,
        selected: true,
      });
    }
  }
);

export const selectedChatAtom = atom((get) => {
  const selectedExternalChat = get(externalSelectedChat);

  if (selectedExternalChat) {
    return selectedExternalChat;
  }

  const selectedChatId = get(selectedChatIdAtom);

  if (!selectedChatId) return;

  const chatAtom = chatListFamily({ id: selectedChatId });

  if (!chatAtom) return;

  const selectedChat = get(chatAtom);

  if (!selectedChat) return;

  return selectedChat;
});
