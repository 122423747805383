import { FC, SVGProps } from 'react';

const SchoolIcon: FC<
  SVGProps<SVGSVGElement> & { size?: number; color?: string }
> = ({ color = '#2d2d2d', size = 24, ...props }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      color={color}
      fill="none"
      {...props}
    >
      <path
        d="M5.04169 14.8125C4.76391 14.6597 4.54863 14.4549 4.39585 14.1979C4.24308 13.941 4.16669 13.6528 4.16669 13.3333V9.33332L2.16669 8.22916C2.01391 8.14582 1.9028 8.04166 1.83335 7.91666C1.76391 7.79166 1.72919 7.65277 1.72919 7.49999C1.72919 7.34721 1.76391 7.20832 1.83335 7.08332C1.9028 6.95832 2.01391 6.85416 2.16669 6.77082L9.20835 2.93749C9.33335 2.86805 9.46183 2.81596 9.59377 2.78124C9.72572 2.74652 9.86113 2.72916 10 2.72916C10.1389 2.72916 10.2743 2.74652 10.4063 2.78124C10.5382 2.81596 10.6667 2.86805 10.7917 2.93749L18.7292 7.27082C18.8681 7.34027 18.9757 7.44096 19.0521 7.57291C19.1285 7.70485 19.1667 7.84721 19.1667 7.99999V13.3333C19.1667 13.5694 19.0868 13.7674 18.9271 13.9271C18.7674 14.0868 18.5695 14.1667 18.3334 14.1667C18.0972 14.1667 17.8993 14.0868 17.7396 13.9271C17.5799 13.7674 17.5 13.5694 17.5 13.3333V8.41666L15.8334 9.33332V13.3333C15.8334 13.6528 15.757 13.941 15.6042 14.1979C15.4514 14.4549 15.2361 14.6597 14.9584 14.8125L10.7917 17.0625C10.6667 17.1319 10.5382 17.184 10.4063 17.2187C10.2743 17.2535 10.1389 17.2708 10 17.2708C9.86113 17.2708 9.72572 17.2535 9.59377 17.2187C9.46183 17.184 9.33335 17.1319 9.20835 17.0625L5.04169 14.8125ZM10 10.5833L15.7084 7.49999L10 4.41666L4.29169 7.49999L10 10.5833ZM10 15.6042L14.1667 13.3542V10.2083L10.8125 12.0625C10.6875 12.1319 10.5556 12.184 10.4167 12.2187C10.2778 12.2535 10.1389 12.2708 10 12.2708C9.86113 12.2708 9.72224 12.2535 9.58335 12.2187C9.44447 12.184 9.31252 12.1319 9.18752 12.0625L5.83335 10.2083V13.3542L10 15.6042Z"
        fill="#007A5A"
      />
    </svg>
  );
};

export default SchoolIcon;
