export interface Integration {
  id: string | null;
  name: string;
  type: IntegrationType;
  integrationSlug?: string;
  status: string;
  feature?: string;
}

export enum IntegrationStatus {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export enum IntegrationType {
  QUICKSELL = 'Quicksell',
  WHATSAPP_CLOUD = 'Whatsapp Cloud Api',
  WHATSAPP_BUSINESS = 'Whatsapp Business Solution',
  OPENAI = 'OpenAI',
}

export interface IntegrationDetails extends Integration {
  credentials: {
    apiKey?: string;
    appSecret?: string;
    channelId?: string;
    phoneName?: string;
    phoneNumber?: string;
    status?: string;
  };
}
